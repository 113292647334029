import { Component } from '@angular/core';
import { NgForOf } from "@angular/common";

@Component({
  selector: 'cs-courts-availability-legend',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './courts-availability-legend.component.html',
  styleUrl: './courts-availability-legend.component.scss'
})
export class CourtsAvailabilityLegendComponent {

  legendItems = [
    { text: 'Available', color: '#FFFFFF', border: '#D9DBE9' },
    { text: 'Events to join', color: '#8CC152', border: '#8CC152' },
    { text: 'Not available', color: '#6E7191', border: '#6E7191' },
    { text: 'Your booking', color: '#007A70', border: '#007A70' },
  ];

}
