import { Component, HostListener, Input } from '@angular/core';
import { DialogService } from "primeng/dynamicdialog";
import { IframeDialogService } from "./iframe-dialog.service";

@Component({
  selector: 'cs-iframe-dialog',
  standalone: true,
  imports: [],
  templateUrl: './iframe-dialog.component.html',
  styleUrl: './iframe-dialog.component.scss'
})
export class IframeDialogComponent {

  @Input() data: any;

  constructor(private dialogService: IframeDialogService) {}

  close() {
    this.dialogService.closeDialog();
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data.type === 'CLOSE_DIALOG') {
      this.close();
    }
  }

}
