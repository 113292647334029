import { CourtsAvailabilitySlot } from "./courts-availability-slot";
import { BookableSlot, Court } from "../../../shared/graphql/generated/graphql";
import { CourtsAvailabilitySlotDurationPrice } from "../interfaces/courts-availability-slot-duration-price";

export class CourtsAvailabilityBookableSlot extends CourtsAvailabilitySlot {
  prices: CourtsAvailabilitySlotDurationPrice[];

  constructor({ startTime, endTime, court, prices }: { startTime: Date, endTime: Date, court: Court, prices: CourtsAvailabilitySlotDurationPrice[] }) {
    super({ startTime, endTime, court });
    this.prices = prices;
  }
}

export function courtsAvailabilityBookableSlotsFromGqlBookableSlots(slots: BookableSlot[], courts: Court[]): CourtsAvailabilityBookableSlot[] {
  return slots.map(slot => {
    const court = courts.find(court => court.id === slot.courtId);
    if (!court) {
      console.error(`Court with id ${slot.courtId} not found`);
      console.error('Available courts', courts);
    }
    return new CourtsAvailabilityBookableSlot({
      startTime: new Date(slot.startTime),
      endTime: new Date(slot.endTime),
      court: court!,
      prices: slot.prices.map(price => ({
        startTime: new Date(slot.startTime),
        endTime: new Date(new Date(slot.startTime).getTime() + price.duration * 60 * 1000),
        price: price.price,
        cashPrice: price.cashPrice as number | undefined,
        duration: price.duration,
      })),
    });
  }).filter(slot => slot.court);
}

