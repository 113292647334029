import { Component, Input } from '@angular/core';
import { CourtsAvailabilitySlotComponent } from "../courts-availability-slot/courts-availability-slot.component";
import { DateTime } from "luxon";
import { SkeletonModule } from "primeng/skeleton";

@Component({
  selector: 'cs-courts-availability-loading-slot',
  standalone: true,
  imports: [
    SkeletonModule
  ],
  templateUrl: './courts-availability-loading-slot.component.html',
  styleUrl: './courts-availability-loading-slot.component.scss'
})
export class CourtsAvailabilityLoadingSlotComponent extends CourtsAvailabilitySlotComponent {

  @Input('endTime') _endTime!: DateTime;
}
