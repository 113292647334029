  <div class="bbq2__grid">
    <div class="bbq2__resources w-5rem lg:w-9rem xl:w-11rem pr-2">
      <div style="height: 40px; line-height: 40px;"></div>
      <div *ngFor="let court of courts" class="bbq2__resource" style="height: 40px; line-height: 40px;">
        <div class="bbq2__resource__label">{{ court.name }}</div>
      </div>
    </div>
    <div class="flex flex-column overflow-hidden">
      <div class="overflow-x-scroll overflow-y-show availability-border" cdkScrollable>
        <div class="bbq2__availability" [style]="{'width': gridWidth()}">
          <div>
            <div class="bbq2__grid-lines" style="background-image: linear-gradient(to right, transparent 0px, transparent 39px, #E2E8F0 39px, #E2E8F0 40px), linear-gradient(transparent 0px, transparent 39px, #E2E8F0 39px, #E2E8F0 40px); background-size: 40px 100%, 100% 40px;"></div>
            <div class="bbq2__hours">
              <div *ngFor="let hour of hours" class="bbq2__hour" style="width: 40px; height: 40px; line-height: 40px;">{{ hour }}</div>
            </div>
            <div class="bbq2__slots">
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      </div>
      <cs-courts-availability-legend></cs-courts-availability-legend>
    </div>
  </div>
