<div class="own-booking absolute"
     [style.left]="calculateSlotLeftOffsetFromStartTime(slot.startTime)"
     [style.width]="calculateWidthFromDuration(slot.startTime, slot.endTime)">
  <div (click)="menu.toggle($event)"></div>
  <p-menu #menu [model]="menuItems" [popup]="true" appendTo="body">
    <ng-template pTemplate="start">
        <div class="px-3 pt-3">
          <div class="font-medium">{{ slot.court.name }}</div>
          <div class="flex">
            <div>{{ slot.startTime | dateInTimezone:'shortTime':timezone }} - {{ slot.endTime | dateInTimezone:'shortTime':timezone }}</div>
          </div>
        </div>
      <p-divider styleClass="mx-1 mt-3 mb-1"></p-divider>
    </ng-template>
    <ng-template pTemplate="item" let-item>
      <a pRipple class="flex align-items-center p-menuitem-link" [ngClass]="item.disabled ? 'text-400' : ''">
        <span [class]="item.icon"></span>
        <span class="ml-2">{{ item.label }}</span>
      </a>
    </ng-template>
  </p-menu>
</div>
