import { Injectable, inject, computed } from '@angular/core';
import { signalStore, withState, withMethods, patchState, withComputed } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { switchMap, catchError, of, tap } from 'rxjs';
import { CourtsAvailabilitySlot } from "../../../../../entities/courts-availability-slot";
import { CourtsAvailabilityBookableSlot } from "../../../../../entities/courts-availability-bookable-slot";
import { DurationPrice } from "../../../../../../../shared/graphql/generated/graphql";
import { CourtsAvailabilitySlotDurationPrice } from "../../../../../interfaces/courts-availability-slot-duration-price";
import { ClubStore } from "../../../../../pages/club-detail-page/club-detail-page.store";
import { DateTime } from "luxon";


interface CourtsAvailabilityState {
  selectedSlot: CourtsAvailabilityBookableSlot | null;
  selectedDuration: CourtsAvailabilitySlotDurationPrice | null;
  timezone: string | null;
  checkoutInProgress: boolean;
}

const initialState: CourtsAvailabilityState = {
  selectedSlot: null,
  selectedDuration: null,
  timezone: null,
  checkoutInProgress: false,
};

export const CourtsAvailabilitySelectedSlotStore = signalStore(
  withState<CourtsAvailabilityState>(initialState),
  withMethods((store) => ({
    selectSlot(slot: CourtsAvailabilityBookableSlot, timezone: string) {
      console.log('selectSlot', slot, timezone);
      console.log('selected slot utc', slot.startDateTime().toUTC().toISO());
      console.log('selected slot local', slot.startDateTime().setZone(timezone).toISO());
      const currentTime = DateTime.now().setZone(timezone);
      if (slot.startDateTime() < currentTime) {
        return;
      }

      const slotDurationInMinutes = (slot.endTime.getTime() - slot.startTime.getTime()) / 60000;
      let durationPrice = slot.prices.find(price => price.duration === slotDurationInMinutes);
      if (!durationPrice) {
        durationPrice = slot.prices[0];
      }

      patchState(store, { selectedSlot: slot, selectedDuration: durationPrice, timezone });
    },
    selectDuration(duration: number) {
      const durationPrice = store.selectedSlot()?.prices.find(price => price.duration === duration);
      if (!durationPrice) return;

      patchState(store, { selectedDuration: durationPrice })
    },
    unselectSlot() {
      if (store.checkoutInProgress()) {
        console.warn('[CourtsAvailabilitySelectedSlotStore] Can not unselect slot while checkout is in progress');
        return;
      }
      patchState(store, { selectedSlot: null, selectedDuration: null })
    },
    setCheckoutInProgress(inProgress: boolean) {
      patchState(store, { checkoutInProgress: inProgress });
    }
  })),
  withComputed((state) => ({
    isDurationSelected: computed(() => {
      return (duration: number) => {
        if (!state.selectedDuration())
          return false;
        return state.selectedDuration()!.duration === duration;
      };
    }),
  }))
);

export type CourtsAvailabilitySelectedSlotStore = InstanceType<typeof CourtsAvailabilitySelectedSlotStore>;
