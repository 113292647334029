import { Component, inject } from '@angular/core';
import { CourtsAvailabilitySlotComponent } from "../courts-availability-slot/courts-availability-slot.component";
import { DatePipe, NgClass } from "@angular/common";
import { MenuItem, PrimeTemplate } from "primeng/api";
import { Router } from "@angular/router";
import { MenuModule } from "primeng/menu";
import { Ripple } from "primeng/ripple";
import { DividerModule } from "primeng/divider";
import { DateInTimezonePipe } from "../../../../../../../shared/pipes/date-in-timezone.pipe";

@Component({
  selector: 'cs-courts-availability-own-booked-slot',
  standalone: true,
  imports: [
    DatePipe,
    MenuModule,
    PrimeTemplate,
    Ripple,
    NgClass,
    DividerModule,
    DateInTimezonePipe
  ],
  templateUrl: './courts-availability-own-booked-slot.component.html',
  styleUrl: './courts-availability-own-booked-slot.component.scss'
})
export class CourtsAvailabilityOwnBookedSlotComponent extends CourtsAvailabilitySlotComponent {

  private readonly router = inject(Router);

  menuItems: MenuItem[] = [
    { label: 'View booking', icon: 'pi pi-eye', command: () =>  {
      this.router.navigate(['/my-bookings']);
      }
    },
  ];

}
