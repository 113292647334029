import { Component, computed, inject, Input, OnDestroy, PLATFORM_ID, signal, Signal } from '@angular/core';
import { CourtsAvailabilitySlotComponent } from "../slots/courts-availability-slot/courts-availability-slot.component";
import { DateTime } from "luxon";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'cs-courts-availability-past-slot-overlay',
  standalone: true,
  imports: [],
  templateUrl: './courts-availability-past-slot-overlay.component.html',
  styleUrl: './courts-availability-past-slot-overlay.component.scss'
})
export class CourtsAvailabilityPastSlotOverlayComponent extends CourtsAvailabilitySlotComponent implements OnDestroy {

  @Input('endTime') _endTime!: DateTime;

  private intervalId: number | null = null;
  private currentTime = signal<DateTime>(DateTime.now().setZone(this.timezone));

  private platformId = inject(PLATFORM_ID);

  constructor() {
    super();
    this.startTimer();
  }

  private startTimer() {
    if (isPlatformBrowser(this.platformId)) {
      this.intervalId = window.setInterval(() => {
        this.currentTime.set(DateTime.now().setZone(this.timezone));
      }, 60000); // Update every minute (60000 ms)
    }
  }

  endTime: Signal<DateTime> = computed(() => {
    return this._endTime < this.currentTime() ? this._endTime : this.currentTime();
  });

  ngOnDestroy() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
  }

}
