import { DateTime } from "luxon";

export function convertTimeToTimezone(
  time: string,
  sourceTimezone: string,
  targetTimezone: string
): string {

  if (sourceTimezone === targetTimezone) {
    return time;
  }
  // Create a DateTime object for the current date in the source timezone
  const sourceDateTime = DateTime.now().setZone(sourceTimezone).set({
    hour: parseInt(time.split(':')[0], 10),
    minute: parseInt(time.split(':')[1], 10),
    second: 0,
    millisecond: 0
  });

  // Convert to the target timezone
  const targetDateTime = sourceDateTime.setZone(targetTimezone);

  // Return the time as a string in "HH:mm" format
  return targetDateTime.toFormat("HH:mm");
}

export function convertTimeToTimezoneIfSourceAndTargetAreSupplied(
  time: string,
  sourceTimezone: string | undefined | null,
  targetTimezone: string | undefined | null
): string {
  if (sourceTimezone && targetTimezone) {
    return convertTimeToTimezone(time, sourceTimezone, targetTimezone);
  }

  return time;
}

export function convertDateValuesToTimezone(date: Date, timezone: string): Date {
  const result = DateTime.fromJSDate(date).setZone(timezone, { keepLocalTime: true }).toJSDate();
  return result;
}

/**
 * Takes a date, and converts (only) the values to the browser timezone from a selected timezone.
 */
export function convertDateValuesFromTimezone(date: Date, timezone: string): Date {
  const dateTime = DateTime.fromJSDate(date).setZone(timezone);
  return dateTime.setZone(DateTime.local().zoneName, { keepLocalTime: true }).toJSDate();
}

export function convertDateTimeValuesFromTimezone(date: DateTime, timezone: string): Date {
  const dateTime = date.setZone(timezone);
  return dateTime.setZone(DateTime.local().zoneName, { keepLocalTime: true }).toJSDate();
}
