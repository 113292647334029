import * as i0 from '@angular/core';
import { ElementRef, InjectionToken, EventEmitter, Injector, PLATFORM_ID, booleanAttribute, Directive, Inject, Input, Output, Injectable, makeEnvironmentProviders, inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import tippy from 'tippy.js';
import { Observable, Subject, fromEvent, merge } from 'rxjs';
import { auditTime, map, switchMap, takeUntil, filter } from 'rxjs/operators';
import * as i1 from '@ngneat/overview';
import { isString as isString$1, isComponent, isTemplateRef } from '@ngneat/overview';
let supportsIntersectionObserver = false;
let supportsResizeObserver = false;
if (typeof window !== 'undefined') {
  supportsIntersectionObserver = 'IntersectionObserver' in window;
  supportsResizeObserver = 'ResizeObserver' in window;
}
function inView(host, options = {
  root: null,
  threshold: 0.3
}) {
  const element = coerceElement(host);
  return new Observable(subscriber => {
    if (!supportsIntersectionObserver) {
      subscriber.next();
      subscriber.complete();
      return;
    }
    const observer = new IntersectionObserver(entries => {
      // Several changes may occur in the same tick, we want to check the latest entry state.
      const entry = entries[entries.length - 1];
      if (entry.isIntersecting) {
        subscriber.next();
        subscriber.complete();
      }
    }, options);
    observer.observe(element);
    return () => observer.disconnect();
  });
}
function isElementOverflow(host) {
  // Don't access the `offsetWidth` multiple times since it triggers layout updates.
  const hostOffsetWidth = host.offsetWidth;
  return hostOffsetWidth > host.parentElement.offsetWidth || hostOffsetWidth < host.scrollWidth;
}
function overflowChanges(host) {
  const element = coerceElement(host);
  return dimensionsChanges(element).pipe(auditTime(150), map(() => isElementOverflow(element)));
}
function dimensionsChanges(target) {
  return resizeObserverStrategy(target);
}
function resizeObserverStrategy(target) {
  return new Observable(subscriber => {
    if (!supportsResizeObserver) {
      subscriber.next();
      subscriber.complete();
      return;
    }
    const observer = new ResizeObserver(() => subscriber.next(true));
    observer.observe(target);
    return () => observer.disconnect();
  });
}
function onlyTippyProps(allProps) {
  const tippyProps = {};
  const ownProps = ['useTextContent', 'variations', 'useHostWidth', 'defaultVariation', 'beforeRender', 'isLazy', 'variation', 'isEnabled', 'className', 'onlyTextOverflow', 'data', 'content', 'context', 'hideOnEscape', 'customHost', 'injector', 'preserveView', 'vcr', 'popperWidth', 'zIndexGetter', 'staticWidthHost'];
  const overriddenMethods = ['onShow', 'onHidden', 'onCreate'];
  Object.keys(allProps).forEach(prop => {
    if (!ownProps.includes(prop) && !overriddenMethods.includes(prop)) {
      tippyProps[prop] = allProps[prop];
    }
  });
  return tippyProps;
}
function normalizeClassName(className) {
  const classes = isString(className) ? className.split(' ') : className;
  return classes.map(klass => klass?.trim()).filter(Boolean);
}
function coerceCssPixelValue(value) {
  if (isNil(value)) {
    return '';
  }
  return typeof value === 'string' ? value : `${value}px`;
}
function isString(value) {
  return typeof value === 'string';
}
function isNil(value) {
  return value === undefined || value === null;
}
function coerceElement(element) {
  return element instanceof ElementRef ? element.nativeElement : element;
}
let observer;
const elementHiddenHandlers = new WeakMap();
function observeVisibility(host, hiddenHandler) {
  observer ??= new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        elementHiddenHandlers.get(entry.target)();
      }
    });
  });
  elementHiddenHandlers.set(host, hiddenHandler);
  observer.observe(host);
  return () => {
    elementHiddenHandlers.delete(host);
    observer.unobserve(host);
  };
}
const TIPPY_CONFIG = new InjectionToken('Tippy config', {
  providedIn: 'root',
  factory() {
    return {};
  }
});
const TIPPY_REF = new InjectionToken('TIPPY_REF');
class TippyDirective {
  set appendTo(appendTo) {
    this.updateProps({
      appendTo
    });
  }
  constructor(platformId, globalConfig, injector, viewService, vcr, zone, hostRef) {
    this.platformId = platformId;
    this.globalConfig = globalConfig;
    this.injector = injector;
    this.viewService = viewService;
    this.vcr = vcr;
    this.zone = zone;
    this.hostRef = hostRef;
    this.onlyTextOverflow = false;
    this.staticWidthHost = false;
    this.useHostWidth = false;
    this.hideOnEscape = false;
    this.detectChangesComponent = true;
    this.isVisible = false;
    this.visible = new EventEmitter();
    this.destroyed = new Subject();
    this.enabled = true;
    this.variationDefined = false;
    /**
     * We had use `visible` event emitter previously as a `takeUntil` subscriber in multiple places
     * within the directive.
     * This is for internal use only; thus we don't have to deal with the `visible` event emitter
     * and trigger change detections only when the `visible` event is being listened outside
     * in the template (`<button [tippy]="..." (visible)="..."></button>`).
     */
    this.visibleInternal = new Subject();
    this.contentChanged = new Subject();
  }
  ngOnChanges(changes) {
    if (this.isServerSide) return;
    let props = Object.keys(changes).reduce((acc, change) => {
      if (change === 'isVisible') return acc;
      acc[change] = changes[change].currentValue;
      return acc;
    }, {});
    let variation;
    if (isChanged('content', changes)) {
      this.contentChanged.next();
    }
    if (isChanged('variation', changes)) {
      variation = changes.variation.currentValue;
      this.variationDefined = true;
    } else if (!this.variationDefined) {
      variation = this.globalConfig.defaultVariation;
      this.variationDefined = true;
    }
    if (variation) {
      props = {
        ...this.globalConfig.variations[variation],
        ...props
      };
    }
    if (isChanged('isEnabled', changes)) {
      this.enabled = changes.isEnabled.currentValue;
      this.setStatus();
    }
    if (isChanged('isVisible', changes)) {
      this.isVisible ? this.show() : this.hide();
    }
    this.updateProps(props);
  }
  ngOnInit() {
    if (this.useHostWidth) {
      this.props.maxWidth = this.hostWidth;
    }
  }
  ngAfterViewInit() {
    if (this.isServerSide) return;
    this.zone.runOutsideAngular(() => {
      if (this.isLazy) {
        if (this.onlyTextOverflow) {
          inView(this.host).pipe(switchMap(() => this.isOverflowing$()), takeUntil(this.destroyed)).subscribe(isElementOverflow => {
            this.checkOverflow(isElementOverflow);
          });
        } else {
          inView(this.host).pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.createInstance();
          });
        }
      } else if (this.onlyTextOverflow) {
        this.isOverflowing$().pipe(takeUntil(this.destroyed)).subscribe(isElementOverflow => {
          this.checkOverflow(isElementOverflow);
        });
      } else {
        this.createInstance();
      }
    });
  }
  ngOnDestroy() {
    this.destroyed.next();
    this.instance?.destroy();
    this.destroyView();
    this.visibilityObserverCleanup?.();
  }
  destroyView() {
    this.viewOptions$ = null;
    this.viewRef?.destroy();
    this.viewRef = null;
  }
  /**
   * This method is useful when you append to an element that you might remove from the DOM.
   * In such cases we want to hide the tooltip and let it go through the destroy lifecycle.
   * For example, if you have a grid row with an element that you toggle using the display CSS property on hover.
   */
  observeHostVisibility() {
    if (this.isServerSide) return;
    // We don't want to observe the host visibility if we are appending to the body.
    if (this.props.appendTo && this.props.appendTo !== document.body) {
      this.visibilityObserverCleanup?.();
      return this.visibleInternal.asObservable().pipe(takeUntil(this.destroyed)).subscribe(isVisible => {
        if (isVisible) {
          this.zone.runOutsideAngular(() => {
            this.visibilityObserverCleanup = observeVisibility(this.instance.reference, () => {
              this.hide();
              // Because we have animation on the popper it doesn't close immediately doesn't trigger the `tpVisible` event.
              // Tippy is relying on the transitionend event to trigger the `onHidden` callback.
              // https://github.com/atomiks/tippyjs/blob/master/src/dom-utils.ts#L117
              // This event never fires because the popper is removed from the DOM before the transition ends.
              if (this.props.animation) {
                this.onHidden();
              }
            });
          });
        } else {
          this.visibilityObserverCleanup?.();
        }
      });
    }
  }
  show() {
    this.instance?.show();
  }
  hide() {
    this.instance?.hide();
  }
  enable() {
    this.instance?.enable();
  }
  disable() {
    this.instance?.disable();
  }
  updateProps(props) {
    this.setProps({
      ...this.props,
      ...props
    });
  }
  setProps(props) {
    this.props = props;
    this.instance?.setProps(onlyTippyProps(props));
  }
  setStatus() {
    this.enabled ? this.instance?.enable() : this.instance?.disable();
  }
  get host() {
    return this.customHost || this.hostRef.nativeElement;
  }
  get hostWidth() {
    return this.host.getBoundingClientRect().width;
  }
  createInstance() {
    if (!this.content && !this.useTextContent) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      this.instance = tippy(this.host, {
        allowHTML: true,
        appendTo: document.body,
        ...(this.globalConfig.zIndexGetter ? {
          zIndex: this.globalConfig.zIndexGetter()
        } : {}),
        ...onlyTippyProps(this.globalConfig),
        ...onlyTippyProps(this.props),
        onMount: instance => {
          this.isVisible = true;
          this.visibleInternal.next(this.isVisible);
          if (this.visible.observed) {
            this.zone.run(() => this.visible.next(this.isVisible));
          }
          this.useHostWidth && this.listenToHostResize();
          this.globalConfig.onMount?.(instance);
        },
        onCreate: instance => {
          instance.popper.classList.add(`tippy-variation-${this.variation || this.globalConfig.defaultVariation}`);
          if (this.className) {
            for (const klass of normalizeClassName(this.className)) {
              instance.popper.classList.add(klass);
            }
          }
          this.globalConfig.onCreate?.(instance);
          if (this.isVisible === true) {
            instance.show();
          }
        },
        onShow: instance => {
          instance.reference.setAttribute('data-tippy-open', '');
          this.zone.run(() => {
            const content = this.resolveContent(instance);
            if (isString$1(content)) {
              instance.setProps({
                allowHTML: false
              });
              if (!content?.trim()) {
                this.disable();
              } else {
                this.enable();
              }
            }
            instance.setContent(content);
            this.hideOnEscape && this.handleEscapeButton();
          });
          if (this.useHostWidth) {
            this.setInstanceWidth(instance, this.hostWidth);
          } else if (this.popperWidth) {
            this.setInstanceWidth(instance, this.popperWidth);
          }
          this.globalConfig.onShow?.(instance);
        },
        onHide(instance) {
          instance.reference.removeAttribute('data-tippy-open');
        },
        onHidden: instance => {
          this.onHidden(instance);
        }
      });
      this.setStatus();
      this.setProps(this.props);
      this.variation === 'contextMenu' && this.handleContextMenu();
    });
  }
  resolveContent(instance) {
    if (!this.viewOptions$ && !isString$1(this.content)) {
      const injector = Injector.create({
        providers: [{
          provide: TIPPY_REF,
          useValue: this.instance
        }],
        parent: this.injector
      });
      if (isComponent(this.content)) {
        this.instance.data = this.data;
        this.viewOptions$ = {
          injector
        };
      } else if (isTemplateRef(this.content)) {
        this.viewOptions$ = {
          injector,
          context: {
            $implicit: this.hide.bind(this),
            data: this.data
          }
        };
      }
    }
    this.viewRef = this.viewService.createView(this.content, {
      vcr: this.vcr,
      ...this.viewOptions$
    });
    // We need to call detectChanges for onPush components to update the content
    if (this.detectChangesComponent && isComponent(this.content)) {
      this.viewRef.detectChanges();
    }
    let content = this.viewRef.getElement();
    if (this.useTextContent) {
      content = instance.reference.textContent;
    }
    if (isString$1(content) && this.globalConfig.beforeRender) {
      content = this.globalConfig.beforeRender(content);
    }
    return content;
  }
  handleContextMenu() {
    fromEvent(this.host, 'contextmenu').pipe(takeUntil(this.destroyed)).subscribe(event => {
      event.preventDefault();
      this.instance.setProps({
        getReferenceClientRect: () => ({
          width: 0,
          height: 0,
          top: event.clientY,
          bottom: event.clientY,
          left: event.clientX,
          right: event.clientX
        })
      });
      this.instance.show();
    });
  }
  handleEscapeButton() {
    this.zone.runOutsideAngular(() => {
      fromEvent(document.body, 'keydown').pipe(filter(({
        code
      }) => code === 'Escape'), takeUntil(merge(this.destroyed, this.visibleInternal.pipe(filter(v => !v))))).subscribe(() => this.hide());
    });
  }
  checkOverflow(isElementOverflow) {
    if (isElementOverflow) {
      if (!this.instance) {
        this.createInstance();
      } else {
        this.instance.enable();
      }
    } else {
      this.instance?.disable();
    }
  }
  listenToHostResize() {
    dimensionsChanges(this.host).pipe(takeUntil(merge(this.destroyed, this.visibleInternal))).subscribe(() => {
      this.setInstanceWidth(this.instance, this.hostWidth);
    });
  }
  setInstanceWidth(instance, width) {
    const inPixels = coerceCssPixelValue(width);
    instance.popper.style.width = inPixels;
    instance.popper.style.maxWidth = inPixels;
    instance.popper.firstElementChild.style.maxWidth = inPixels;
  }
  get isServerSide() {
    return isPlatformServer(this.platformId);
  }
  onHidden(instance = this.instance) {
    this.destroyView();
    this.isVisible = false;
    this.visibleInternal.next(this.isVisible);
    if (this.visible.observed) {
      this.zone.run(() => this.visible.next(this.isVisible));
    }
    this.globalConfig.onHidden?.(instance);
  }
  isOverflowing$() {
    const notifiers$ = [overflowChanges(this.host)];
    // We need to handle cases where the host has a static width but the content might change
    if (this.staticWidthHost) {
      notifiers$.push(this.contentChanged.asObservable().pipe(
      // We need to wait for the content to be rendered before we can check if it's overflowing.
      switchMap(() => {
        return new Observable(subscriber => {
          const id = window.requestAnimationFrame(() => {
            subscriber.next();
            subscriber.complete();
          });
          return () => cancelAnimationFrame(id);
        });
      }), map(() => isElementOverflow(this.host))));
    }
    return merge(...notifiers$);
  }
  static {
    this.ɵfac = function TippyDirective_Factory(ɵt) {
      return new (ɵt || TippyDirective)(i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(TIPPY_CONFIG), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i1.ViewService), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TippyDirective,
      selectors: [["", "tp", ""]],
      inputs: {
        appendTo: [0, "tpAppendTo", "appendTo"],
        content: [0, "tp", "content"],
        delay: [0, "tpDelay", "delay"],
        duration: [0, "tpDuration", "duration"],
        hideOnClick: [0, "tpHideOnClick", "hideOnClick"],
        interactive: [0, "tpInteractive", "interactive"],
        interactiveBorder: [0, "tpInteractiveBorder", "interactiveBorder"],
        maxWidth: [0, "tpMaxWidth", "maxWidth"],
        offset: [0, "tpOffset", "offset"],
        placement: [0, "tpPlacement", "placement"],
        popperOptions: [0, "tpPopperOptions", "popperOptions"],
        showOnCreate: [0, "tpShowOnCreate", "showOnCreate"],
        trigger: [0, "tpTrigger", "trigger"],
        triggerTarget: [0, "tpTriggerTarget", "triggerTarget"],
        zIndex: [0, "tpZIndex", "zIndex"],
        animation: [0, "tpAnimation", "animation"],
        useTextContent: [2, "tpUseTextContent", "useTextContent", booleanAttribute],
        isLazy: [2, "tpIsLazy", "isLazy", booleanAttribute],
        variation: [0, "tpVariation", "variation"],
        isEnabled: [0, "tpIsEnabled", "isEnabled"],
        className: [0, "tpClassName", "className"],
        onlyTextOverflow: [2, "tpOnlyTextOverflow", "onlyTextOverflow", booleanAttribute],
        staticWidthHost: [2, "tpStaticWidthHost", "staticWidthHost", booleanAttribute],
        data: [0, "tpData", "data"],
        useHostWidth: [2, "tpUseHostWidth", "useHostWidth", booleanAttribute],
        hideOnEscape: [2, "tpHideOnEscape", "hideOnEscape", booleanAttribute],
        detectChangesComponent: [0, "tpDetectChangesComponent", "detectChangesComponent"],
        popperWidth: [0, "tpPopperWidth", "popperWidth"],
        customHost: [0, "tpHost", "customHost"],
        isVisible: [2, "tpIsVisible", "isVisible", booleanAttribute]
      },
      outputs: {
        visible: "tpVisible"
      },
      exportAs: ["tippy"],
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TippyDirective, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: '[tp]',
      exportAs: 'tippy',
      standalone: true
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [TIPPY_CONFIG]
    }]
  }, {
    type: i0.Injector
  }, {
    type: i1.ViewService
  }, {
    type: i0.ViewContainerRef
  }, {
    type: i0.NgZone
  }, {
    type: i0.ElementRef
  }], {
    appendTo: [{
      type: Input,
      args: ['tpAppendTo']
    }],
    content: [{
      type: Input,
      args: ['tp']
    }],
    delay: [{
      type: Input,
      args: ['tpDelay']
    }],
    duration: [{
      type: Input,
      args: ['tpDuration']
    }],
    hideOnClick: [{
      type: Input,
      args: ['tpHideOnClick']
    }],
    interactive: [{
      type: Input,
      args: ['tpInteractive']
    }],
    interactiveBorder: [{
      type: Input,
      args: ['tpInteractiveBorder']
    }],
    maxWidth: [{
      type: Input,
      args: ['tpMaxWidth']
    }],
    offset: [{
      type: Input,
      args: ['tpOffset']
    }],
    placement: [{
      type: Input,
      args: ['tpPlacement']
    }],
    popperOptions: [{
      type: Input,
      args: ['tpPopperOptions']
    }],
    showOnCreate: [{
      type: Input,
      args: ['tpShowOnCreate']
    }],
    trigger: [{
      type: Input,
      args: ['tpTrigger']
    }],
    triggerTarget: [{
      type: Input,
      args: ['tpTriggerTarget']
    }],
    zIndex: [{
      type: Input,
      args: ['tpZIndex']
    }],
    animation: [{
      type: Input,
      args: ['tpAnimation']
    }],
    useTextContent: [{
      type: Input,
      args: [{
        transform: booleanAttribute,
        alias: 'tpUseTextContent'
      }]
    }],
    isLazy: [{
      type: Input,
      args: [{
        transform: booleanAttribute,
        alias: 'tpIsLazy'
      }]
    }],
    variation: [{
      type: Input,
      args: ['tpVariation']
    }],
    isEnabled: [{
      type: Input,
      args: ['tpIsEnabled']
    }],
    className: [{
      type: Input,
      args: ['tpClassName']
    }],
    onlyTextOverflow: [{
      type: Input,
      args: [{
        transform: booleanAttribute,
        alias: 'tpOnlyTextOverflow'
      }]
    }],
    staticWidthHost: [{
      type: Input,
      args: [{
        transform: booleanAttribute,
        alias: 'tpStaticWidthHost'
      }]
    }],
    data: [{
      type: Input,
      args: ['tpData']
    }],
    useHostWidth: [{
      type: Input,
      args: [{
        transform: booleanAttribute,
        alias: 'tpUseHostWidth'
      }]
    }],
    hideOnEscape: [{
      type: Input,
      args: [{
        transform: booleanAttribute,
        alias: 'tpHideOnEscape'
      }]
    }],
    detectChangesComponent: [{
      type: Input,
      args: ['tpDetectChangesComponent']
    }],
    popperWidth: [{
      type: Input,
      args: ['tpPopperWidth']
    }],
    customHost: [{
      type: Input,
      args: ['tpHost']
    }],
    isVisible: [{
      type: Input,
      args: [{
        transform: booleanAttribute,
        alias: 'tpIsVisible'
      }]
    }],
    visible: [{
      type: Output,
      args: ['tpVisible']
    }]
  });
})();
function isChanged(key, changes) {
  return key in changes;
}
const tooltipVariation = {
  theme: null,
  arrow: false,
  animation: 'scale',
  trigger: 'mouseenter',
  offset: [0, 5]
};
const popperVariation = {
  theme: 'light',
  arrow: true,
  offset: [0, 10],
  animation: null,
  trigger: 'click',
  interactive: true
};
function withContextMenuVariation(baseVariation) {
  return {
    ...baseVariation,
    placement: 'right-start',
    trigger: 'manual',
    arrow: false,
    offset: [0, 0]
  };
}
class TippyService {
  constructor(globalConfig, view, injector) {
    this.globalConfig = globalConfig;
    this.view = view;
    this.injector = injector;
  }
  create(host, content, options = {}) {
    const variation = options.variation || this.globalConfig.defaultVariation;
    const config = {
      onShow: instance => {
        host.setAttribute('data-tippy-open', '');
        if (!instance.$viewOptions) {
          instance.$viewOptions = {};
          const injector = Injector.create({
            providers: [{
              provide: TIPPY_REF,
              useValue: instance
            }],
            parent: options.injector || this.injector
          });
          instance.$viewOptions.injector = injector;
          if (isTemplateRef(content)) {
            instance.$viewOptions.context = {
              $implicit: instance.hide.bind(instance),
              ...options.context
            };
          } else if (isComponent(content)) {
            instance.context = options.context;
            instance.data = options.data;
          }
        }
        if (!instance.view) {
          instance.view = this.view.createView(content, {
            ...options,
            ...instance.$viewOptions
          });
        }
        instance.setContent(instance.view.getElement());
        options?.onShow?.(instance);
      },
      onHidden: instance => {
        host.removeAttribute('data-tippy-open');
        if (!options.preserveView) {
          instance.view.destroy();
          instance.view = null;
        }
        options?.onHidden?.(instance);
      },
      ...onlyTippyProps(this.globalConfig),
      ...this.globalConfig.variations[variation],
      ...onlyTippyProps(options),
      onCreate: instance => {
        instance.popper.classList.add(`tippy-variation-${variation}`);
        if (options.className) {
          for (const klass of normalizeClassName(options.className)) {
            instance.popper.classList.add(klass);
          }
        }
        this.globalConfig.onCreate?.(instance);
        options.onCreate?.(instance);
      }
    };
    return tippy(host, config);
  }
  static {
    this.ɵfac = function TippyService_Factory(ɵt) {
      return new (ɵt || TippyService)(i0.ɵɵinject(TIPPY_CONFIG), i0.ɵɵinject(i1.ViewService), i0.ɵɵinject(i0.Injector));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TippyService,
      factory: TippyService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TippyService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [TIPPY_CONFIG]
    }]
  }, {
    type: i1.ViewService
  }, {
    type: i0.Injector
  }], null);
})();
function provideTippyConfig(config = {}) {
  return makeEnvironmentProviders([{
    provide: TIPPY_CONFIG,
    useValue: config
  }]);
}
function injectTippyRef() {
  const instance = inject(TIPPY_REF, {
    optional: true
  });
  if (instance) {
    return instance;
  }
  throw new Error('tp is not provided in the current context or on one of its ancestors');
}

/**
 * Generated bundle index. Do not edit.
 */

export { TIPPY_CONFIG, TIPPY_REF, TippyDirective, TippyService, inView, injectTippyRef, overflowChanges, popperVariation, provideTippyConfig, tooltipVariation, withContextMenuVariation };
