import { Directive, ElementRef, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

@Directive({
  selector: '[csOverlayClickOutside]',
  standalone: true
})
export class OverlayClickOutsideDirective {

  @Output() clickOutside = new EventEmitter<void>();

  private overlayRef?: OverlayRef;
  private triggerElement?: HTMLElement;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    setTimeout(() => {
      this.addClickOutsideListener();
    });
  }

  ngOnDestroy() {
    this.removeClickOutsideListener();
  }

  setOverlayRef(overlayRef: OverlayRef) {
    this.overlayRef = overlayRef;
  }

  setTriggerElement(element: HTMLElement) {
    this.triggerElement = element;
  }

  private addClickOutsideListener() {
    document.addEventListener('click', this.clickListener);
  }

  private removeClickOutsideListener() {
    document.removeEventListener('click', this.clickListener);
  }

  private clickListener = (event: MouseEvent) => {
    const clickedElement = event.target as HTMLElement;
    const overlayElement = this.overlayRef?.overlayElement;

    if (clickedElement &&
      !this.elementRef.nativeElement.contains(clickedElement) &&
      !this.triggerElement?.contains(clickedElement) &&
      !overlayElement?.contains(clickedElement)) {
      this.clickOutside.emit();
    }
  }

}
