import { Court, DurationPrice } from "../../../shared/graphql/generated/graphql";
import { DateTime } from "luxon";

export class CourtsAvailabilitySlot {
  startTime: Date;
  endTime: Date;
  court: Court;

  constructor({ startTime, endTime, court }: { startTime: Date, endTime: Date, court: Court }) {
    this.startTime = startTime;
    this.endTime = endTime;
    this.court = court;
  }

  startDateTime(): DateTime {
    return DateTime.fromJSDate(this.startTime);
  }

  endDateTime(): DateTime {
    return DateTime.fromJSDate(this.endTime);
  }
}
