<!--<div class="selected-slot" #selectedSlotEl [hidden]="!selectedSlotStore.selectedSlot()"
     [style.top]="getSelectedSlotTopOffsetByCourtId(selectedSlotStore.selectedSlot()?.court?.id)"
     [style.left]="calculateSlotLeftOffsetFromStartTime(selectedSlotStore.selectedSlot()?.startTime)">
  <div class="duration-bar"
       [style.width]="calculateWidthFromDuration(selectedSlotStore.selectedSlot()?.startTime, selectedSlotStore.selectedDuration()?.endTime)"
       style="height: 40px; width: 81px;">
    <div></div>
  </div>
  <div class="tooltip p-2 bg-white border-round shadow-1" #selectedSlotTooltip>

  </div>
</div>-->

<div class="selected-slot" #selectedSlotEl
     [style.top]="getSelectedSlotTopOffsetByCourtId(selectedSlotStore.selectedSlot()?.court?.id)"
     [style.left]="calculateSlotLeftOffsetFromStartTime(selectedSlotStore.selectedSlot()?.startTime)">
  <div class="duration-bar" #trigger
       [style.width]="calculateWidthFromDuration(selectedSlotStore.selectedSlot()?.startTime, selectedSlotStore.selectedDuration()?.endTime)"
       style="height: 40px; width: 81px;">
    <div></div>
  </div>
</div>

<!--<ng-template #tooltipTemplate
             cdkConnectedOverlay
             cdkConnectedOverlayPush="true"
             [cdkConnectedOverlayOrigin]="trigger"
             [cdkConnectedOverlayOpen]="modalShown"
             [cdkConnectedOverlayPositions]="[
        {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center'
        },
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'top'
        },
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'bottom'
        }
    ]">
>-->
<ng-template #tooltipTemplate>
  <div csOverlayClickOutside #tooltipContent id="selected-slot-tooltip-content" class="tooltip bg-white p-2 border-round shadow-2" style="z-index: 1000" (clickOutside)="clickOutsideOverlay()">
    <ng-container *ngIf="selectedSlotStore.selectedSlot() as selectedSlot">
        <div class="flex justify-content-between align-items-center mb-2">
          <div class="font-medium">{{ selectedSlot.court.name }}</div>
          <div class="close-btn" (click)="unselectSlot()">
            <div class="pi pi-times"></div>
          </div>
        </div>
        <ng-container *ngFor="let durationPrice of selectedSlot.prices">
          <div class="text-sm p-2 mt-2 duration-option"
               [style.border-color]="isDurationPriceSelected(durationPrice) ? 'var(--primary-color)' : '#EFF6FF'"
               (click)="selectDurationPrice(durationPrice)">
            <div class="flex">
              <div>{{ durationPrice.startTime | dateInTimezone:'shortTime':timezone }} - {{ durationPrice.endTime | dateInTimezone:'shortTime':timezone }}</div>
              <div class="ml-2">({{ durationPrice.duration }}min)</div>
            </div>
            <div>{{ formatPrice(durationPrice.price) }}</div>
          </div>
        </ng-container>
        <div class="mt-3">
          <p-button size="small" [label]="getContinueButtonLabel()" styleClass="w-full text-base py-2" (onClick)="bookSlot()"></p-button>
        </div>
    </ng-container>
  </div>
</ng-template>
