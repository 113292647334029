import {
  Component,
  computed,
  EnvironmentInjector,
  inject,
  Inject,
  PLATFORM_ID,
  runInInjectionContext
} from '@angular/core';
import { Button } from "primeng/button";
import { AsyncPipe, CurrencyPipe, DatePipe, NgIf } from "@angular/common";
import { CheckoutDialogComponent } from "../../../checkout/components/checkout-dialog/checkout-dialog.component";
import { combineLatestWith, map, Observable } from "rxjs";
import { CheckoutParams } from "../../../checkout/components/stripe-checkout/stripe-checkout.component";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Store } from "@ngrx/store";
import { toObservable } from "@angular/core/rxjs-interop";
import {
  CourtsAvailabilitySelectedSlotStore
} from "../court-availability/components/slots/courts-availability-selected-slot/courts-availability-selected-slot.store";
import { ClubsService } from "../../services/clubs.service";
import { ClubStore } from "../../pages/club-detail-page/club-detail-page.store";
import { PaymentMethod } from "../../../../shared/graphql/generated/graphql";
import { DateInTimezonePipe } from "../../../../shared/pipes/date-in-timezone.pipe";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'cs-court-reservation-checkout-dialog',
  standalone: true,
    imports: [
        Button,
        NgIf,
        CheckoutDialogComponent,
        DatePipe,
        CurrencyPipe,
        DateInTimezonePipe,
        AsyncPipe
    ],
  templateUrl: './court-reservation-checkout-dialog.component.html',
  styleUrl: './court-reservation-checkout-dialog.component.scss'
})
export class CourtReservationCheckoutDialogComponent {

  checkoutParams$!: Observable<CheckoutParams>;

  constructor(public dialogRef: DynamicDialogRef,
              private dialogConf: DynamicDialogConfig,
              private clubsService: ClubsService,
              private store: Store, @Inject(PLATFORM_ID) private platformId: Object
  ) {

    this.selectedSlotStore = this.dialogConf.data.selectedSlotStore;
  }

  selectedSlotStore!: CourtsAvailabilitySelectedSlotStore;
  clubStore!: ClubStore;

  router = inject(Router);

  cashPaymentPossible = computed(() => this.clubStore.cashPaymentEnabled() && this.selectedSlotStore.selectedDuration()?.cashPrice);

  initializeReservationPayment(params: CheckoutParams) {
    console.log('initializeReservationPayment', params);
    return this.clubsService.initializePayment({
      courtId: params['courtId'],
      startTime: params['startTime'],
      duration: params['duration'],
    });
  }

  injector = inject(EnvironmentInjector);

  reservationByCashPayment(params: CheckoutParams) {
    return this.clubsService.courtReservationByCashPayment({
      courtId: params['courtId'],
      startTime: params['startTime'],
      duration: params['duration'],
    });
  }

  refundableUntil = computed(() => {
    const refundableUntilMin = this.clubStore.club()!.courtReservationsRefundableUntil;
    return new Date(this.selectedSlotStore.selectedSlot()!.startTime.getTime() - refundableUntilMin * 60 * 1000);
  });

  ngOnInit(): void {
    this.selectedSlotStore = this.dialogConf.data.selectedSlotStore;
    this.clubStore = this.dialogConf.data.clubStore;

    const currentPath = this.router.url;

    runInInjectionContext(this.injector, () => {
      this.checkoutParams$ = toObservable(this.selectedSlotStore.selectedSlot).pipe(
        combineLatestWith(toObservable(this.selectedSlotStore.selectedDuration)),
        map(([selectedSlot, selectedDuration]) => {
          return {
            courtId: selectedSlot!.court.id,
            startTime: selectedSlot!.startTime,
            duration: selectedDuration!.duration,
            price: selectedDuration!.price,
            paymentMethods: this.clubStore.club()!.paymentMethods,
            returnPath: currentPath,
            stripePublishableKey: environment.stripePublishableKey
          };
        })
      );
    });
  }

}
