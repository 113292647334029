import { Component, inject, PLATFORM_ID } from '@angular/core';
import { CourtsAvailabilitySlotComponent } from "../courts-availability-slot/courts-availability-slot.component";
import { DatePipe, isPlatformBrowser, NgTemplateOutlet } from "@angular/common";
import { DateInTimezonePipe } from "../../../../../../../shared/pipes/date-in-timezone.pipe";
import { NgxTippyModule } from "ngx-tippy-wrapper";

@Component({
  selector: 'cs-courts-availability-bookable-slot',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    DatePipe,
    DateInTimezonePipe,
    NgxTippyModule
  ],
  templateUrl: './courts-availability-bookable-slot.component.html',
  styleUrl: './courts-availability-bookable-slot.component.scss'
})
export class CourtsAvailabilityBookableSlotComponent extends CourtsAvailabilitySlotComponent {

  platformId = inject(PLATFORM_ID);

  isTouchDevice() {
    return isPlatformBrowser(this.platformId) && (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0))
  }

}
