import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector
} from '@angular/core';
import { IframeDialogComponent } from "./iframe-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class IframeDialogService {

  private dialogComponentRef!: ComponentRef<IframeDialogComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  openDialog(data: any) {
    if (this.dialogComponentRef) {
      this.closeDialog();
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(IframeDialogComponent);
    const componentRef = componentFactory.create(this.injector);
    componentRef.instance.data = data;

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;

    window.parent.postMessage({ type: 'DIALOG_OPENED' }, '*');
  }

  closeDialog() {
    if (this.dialogComponentRef) {
      this.appRef.detachView(this.dialogComponentRef.hostView);
      this.dialogComponentRef.destroy();
      window.parent.postMessage({ type: 'DIALOG_CLOSED' }, '*');
    }
  }

}
