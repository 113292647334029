<div [style.left]="calculateSlotLeftOffsetFromStartTime(slot.startTime)"
     [style.width]="calculateWidthFromDuration(slot.startTime, slot.endTime)" class="h-full absolute">
  <div class="bbq2-open"
       data-tippy-singleton
       [ngxTippy]="tooltipTemplate"
       [tippyProps]="{
        placement: 'top',
        delay: [20, 0],
        duration: [100, 50],
        touch: false
      }">
    <div class="bbq2-open__fill"
         [style.width]="calculateWidthFromDuration(slot.startTime, slot.endTime)">
      <div></div>
    </div>
    <!--<div class="tooltip p-3 border-round shadow-1">
      <div class="tooltip-content text-center">
        <div class="mb-1">{{ slot.court.name }}</div>
        <div>{{ slot.startDateTime().toJSDate() | dateInTimezone: 'shortTime':timezone }} - {{ slot.endTime | dateInTimezone: 'shortTime':timezone }}</div>
      </div>
      <div class="tooltip-arrow"></div>
    </div>-->
  </div>
</div>

<ng-template #tooltipTemplate>
  <div class="tooltip-content text-center">
    <div class="mb-1">{{ slot.court.name }}</div>
    <div>{{ slot.startDateTime().toJSDate() | dateInTimezone: 'shortTime':timezone }} - {{ slot.endTime | dateInTimezone: 'shortTime':timezone }}</div>
  </div>
</ng-template>
