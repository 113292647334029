import { Injectable, inject, computed } from '@angular/core';
import { signalStore, withState, withMethods, patchState, withComputed, withHooks } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { switchMap, catchError, of, tap, pipe } from 'rxjs';
import { ClubsService } from "../../services/clubs.service";
import { BookableSlot, Court } from "../../../../shared/graphql/generated/graphql";
import {
  CourtsAvailabilityBookableSlot, courtsAvailabilityBookableSlotsFromGqlBookableSlots
} from "../../entities/courts-availability-bookable-slot";
import { CourtsAvailabilitySlot } from "../../entities/courts-availability-slot";
import { DateTime } from "luxon";
import {
  courtsAvailabilityOwnBookedSlotsFromGqlCourtReservations, CourtsAvailabilityBookedSlot
} from "../../entities/courts-availability-booked-slot";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorCodeService } from "../../../../shared/services/error-code.service";
import { Location } from "@angular/common";

interface CourtsAvailabilityState {
  bookableSlots: CourtsAvailabilityBookableSlot[];
  ownBookedSlots: CourtsAvailabilityBookedSlot[];
  courts: Court[];
  selectedDate: DateTime;
  selectedSportId: number | null;
  loading: boolean;
  error: string | null;
  sportIds: number[];
  clubId: string;
  timezone: string;
}

const initialState: CourtsAvailabilityState = {
  bookableSlots: [],
  ownBookedSlots: [],
  courts: [],
  selectedDate: DateTime.now(),
  selectedSportId: null,
  loading: false,
  error: null,
  sportIds: [],
  clubId: '',
  timezone: '',
};

export interface CourtsAvailabilityConfig {
  sportIds: number[];
  clubId: string;
  initialSportId?: number;
  timezone: string;
  courts: Court[];
}

export const CourtsAvailabilityStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store, clubsService = inject(ClubsService), router = inject(Router), activatedRoute = inject(ActivatedRoute), errorMessageService = inject(ErrorCodeService), location = inject(Location)) => ({
    initStore({ sportIds, clubId, initialSportId, timezone, courts }: CourtsAvailabilityConfig) {
      console.log('CourtsAvailabilityStore initStore', { sportIds, clubId, initialSportId, timezone, courts });

      const sportIdFromRouter = activatedRoute.snapshot.queryParamMap.get('sportId');
      const selectedSportId = initialSportId || (sportIdFromRouter ? parseInt(sportIdFromRouter) : sportIds[0]);

      if (!sportIdFromRouter) {
        const url = router.createUrlTree([], {relativeTo: activatedRoute, queryParams: { sportId: selectedSportId }, queryParamsHandling: 'merge'}).toString();
        location.go(url);
      }

      const dateFromRouter = activatedRoute.snapshot.queryParamMap.get('date');
      const dateTimeFromRouter = dateFromRouter ? DateTime.fromISO(dateFromRouter).setZone(timezone).startOf('day') : null;
      console.log('dateFromRouter', dateFromRouter);

      const currentDate = DateTime.now().setZone(timezone).startOf('day');
      if ((dateTimeFromRouter && dateTimeFromRouter < currentDate) || !dateFromRouter) {
        const url = router.createUrlTree([], {relativeTo: activatedRoute, queryParams: { date: currentDate.toISODate() }, queryParamsHandling: 'merge'}).toString();
        location.go(url);
      }

      const selectedDate = dateTimeFromRouter && dateTimeFromRouter >= currentDate ? dateTimeFromRouter : currentDate;
      console.log('selectedDate', selectedDate);

      patchState(store, { sportIds, selectedSportId, clubId, timezone, loading: true, selectedDate: selectedDate, courts });
    },
    loadCourtAvailability: rxMethod<void>(pipe(
      tap(() => patchState(store, { loading: true, error: null, bookableSlots: [] })),
      switchMap(() => {
        const startDate = store.selectedDate().toJSDate();
        const endDate = store.selectedDate().endOf('day').toJSDate();

        return clubsService.getCourtsAvailability({
          clubId: store.clubId(),
          startDate: startDate,
          endDate: endDate,
          sportIds: [store.selectedSportId()!],
        }).pipe(
          tap((courtAvailability) => {
            patchState(store, {
              bookableSlots: courtsAvailabilityBookableSlotsFromGqlBookableSlots(courtAvailability.bookableSlots, store.courts()),
              ownBookedSlots: courtsAvailabilityOwnBookedSlotsFromGqlCourtReservations(courtAvailability.courtReservations, store.courts()),
              loading: false,
            });
            console.log('courtAvailability loaded', courtAvailability);
          }),
          catchError((error) => {
            console.error('Error loading court availability', error);
            const errorMessage = errorMessageService.formatErrorToMessage(error);
            patchState(store, { error: errorMessage, loading: false });
            return of(null);
          })
        );
      })
    )),

    setSelectedSport(sportId: number) {
      const url = router.createUrlTree([], {relativeTo: activatedRoute, queryParams: { sportId: sportId }, queryParamsHandling: 'merge'}).toString();
      location.go(url);

      patchState(store, { selectedSportId: sportId });
      this.loadCourtAvailability();
    },

    setSelectedDate(date: Date) {
      const dateTime = DateTime.fromJSDate(date).setZone(store.timezone());
      const startDateTime = dateTime.startOf('day');

      const dateTimeToday = DateTime.now().setZone(store.timezone());
      const startDateTimeToday = dateTimeToday.startOf('day');

      const normalizedDate = startDateTimeToday < startDateTime ? startDateTime : startDateTimeToday;

      const url = router.createUrlTree([], {relativeTo: activatedRoute, queryParams: { date: normalizedDate.toISODate() }, queryParamsHandling: 'merge' }).toString();
      location.go(url);

      patchState(store, { selectedDate: normalizedDate });
      this.loadCourtAvailability();
    },
  })),
  withComputed(({ selectedDate, timezone }) => ({
    startTime: computed(() => {
      return selectedDate().set({ hour: 7, minute: 0, second: 0 });
    }),
    endTime: computed(() => {
      return selectedDate().endOf('day');
    }),
  })),
  withComputed(({ startTime, endTime, bookableSlots,courts, ownBookedSlots, selectedSportId }) => ({
    hours: computed(() => {
      const hours: string[] = [];
      const startHour = startTime().hour;
      let endHour = endTime().hour;
      if (endHour == 0) {
        endHour = 23;
      }

      for (let i = startHour; i <= endHour; i++) {
        if (i < 10) {
          hours.push(`0${i}`);
          continue;
        }
        hours.push(`${i}`);
      }
      return hours;
    }),
    getBookableSlotsByCourt: computed(() => {
      return (courtId: string) => {
        if (!bookableSlots()) return [];
        return bookableSlots()!.filter(slot => slot.court.id === courtId);
      };
    }),
    getOwnBookedSlotsByCourt: computed(() => {
      return (courtId: string) => {
        if (!ownBookedSlots()) return [];
        return ownBookedSlots()!.filter(slot => slot.court.id === courtId);
      };
    }),
    getBlockedSlotsByCourt: computed(() => {
      return (courtId: string) => {
        const court = courts().find(court => court.id === courtId);
        if (!court) return [];

        const bookableAndOwnBookedSlots: CourtsAvailabilitySlot[] = [...bookableSlots()!, ...ownBookedSlots()!];
        const bookableAndOwnBookedSlotsByCourt = bookableAndOwnBookedSlots
          .filter(slot => slot.court.id === courtId);
        const blockedSlots: CourtsAvailabilitySlot[] = [];

        const slotsSortedByStartTime = bookableAndOwnBookedSlotsByCourt.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
        let currentTime = startTime();
        for (const slot of slotsSortedByStartTime) {
          if (currentTime < slot.startDateTime()) {
            blockedSlots.push(new CourtsAvailabilitySlot({
              startTime: currentTime.toJSDate(),
              endTime: slot.startTime,
              court: court,
            }));
          }
          currentTime = slot.endDateTime();
        }

        if (currentTime < endTime()) {
          blockedSlots.push(new CourtsAvailabilitySlot({
            startTime: currentTime.toJSDate(),
            endTime: endTime().toJSDate(),
            court: court
          }));
        }

        return blockedSlots;
      };
    }),
    courtsForSport: computed(() => {
      const courtsFiltered = [...courts().filter(court => court.courtType?.sportId === selectedSportId())];
      courtsFiltered.sort((a, b) => a.position - b.position);
      return courtsFiltered;
    }),
  })),
  withHooks({
    onInit: (store) => {

    }
  })
);
