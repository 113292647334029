import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { Court } from "../../../../../../shared/graphql/generated/graphql";
import { NgForOf, NgStyle } from "@angular/common";
import { CourtsAvailabilityLegendComponent } from "../courts-availability-legend/courts-availability-legend.component";
import { CdkScrollable } from "@angular/cdk/overlay";
import { NgxTippyModule } from "ngx-tippy-wrapper";

@Component({
  selector: 'cs-courts-availability-grid',
  standalone: true,
  imports: [
    NgStyle,
    NgForOf,
    CourtsAvailabilityLegendComponent,
    CdkScrollable,
    NgxTippyModule
  ],
  templateUrl: './courts-availability-grid.component.html',
  styleUrl: './courts-availability-grid.component.scss'
})
export class CourtsAvailabilityGridComponent {

  @Input() courts: Court[] = [];
  @Input() hours: string[] = [];

  @ContentChild(TemplateRef) slotTemplate!: TemplateRef<any>;

  gridWidth() {
    return (this.hours.length * 40 + 1) + 'px';
  }
}
