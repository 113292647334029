// courts-availability.component.ts
import {
  Component,
  Input,
  OnInit,
  inject,
  ViewChild,
  ViewChildren,
  ElementRef,
  QueryList,
  Renderer2, Signal, computed, OnDestroy, DestroyRef, Inject, PLATFORM_ID, HostListener, effect
} from '@angular/core';
import { CourtsAvailabilityConfig, CourtsAvailabilityStore } from './courts-availability.store';
import {
  CourtsAvailabilityGridComponent
} from "./components/courts-availability-grid/courts-availability-grid.component";
import {
  CourtsAvailabilityBlockedSlotComponent
} from "./components/slots/courts-availability-blocked-slot/courts-availability-blocked-slot.component";
import {
  CourtsAvailabilityBookableSlotComponent
} from "./components/slots/courts-availability-bookable-slot/courts-availability-bookable-slot.component";
import { isPlatformBrowser, NgForOf, NgIf } from "@angular/common";
import {
  CourtsAvailabilitySelectedSlotStore
} from "./components/slots/courts-availability-selected-slot/courts-availability-selected-slot.store";
import { CourtsAvailabilityBookableSlot } from "../../entities/courts-availability-bookable-slot";
import {
  CourtsAvailabilitySelectedSlotComponent
} from "./components/slots/courts-availability-selected-slot/courts-availability-selected-slot.component";
import {
  CourtsAvailabilitySlotComponent
} from "./components/slots/courts-availability-slot/courts-availability-slot.component";
import { CalendarModule } from "primeng/calendar";
import { FormsModule } from "@angular/forms";
import {
  CourtsAvailabilityPastSlotOverlayComponent
} from "./components/courts-availability-past-slot-overlay/courts-availability-past-slot-overlay.component";
import { getSportNameById } from "../../../../shared/utils/sport.utils";
import { DropdownModule } from "primeng/dropdown";
import {
  CourtsAvailabilityLegendComponent
} from "./components/courts-availability-legend/courts-availability-legend.component";
import { ClubStore } from "../../pages/club-detail-page/club-detail-page.store";
import {
  CourtsAvailabilityOwnBookedSlotComponent
} from "./components/slots/courts-availability-own-booked-slot/courts-availability-own-booked-slot.component";
import {
  convertDateTimeValuesFromTimezone,
  convertDateValuesFromTimezone,
  convertDateValuesToTimezone
} from "../../../../shared/utils/localization.utils";
import { DateTime } from "luxon";
import {
  PaymentProcessingStatusDialogComponent
} from "../../../../shared/components/payment-processing-status-dialog/payment-processing-status-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CourtsAvailabilityLoadingSlotComponent
} from "./components/slots/courts-availability-loading-slot/courts-availability-loading-slot.component";
import { MessagesModule } from "primeng/messages";
import { BookableSlot } from "../../../../shared/graphql/generated/graphql";

@Component({
  selector: 'cs-courts-availability',
  templateUrl: './court-availability.component.html',
  styleUrls: ['./court-availability.component.scss'],
  standalone: true,
  imports: [
    CourtsAvailabilityGridComponent,
    CourtsAvailabilityBlockedSlotComponent,
    CourtsAvailabilityBookableSlotComponent,
    NgForOf,
    CourtsAvailabilitySelectedSlotComponent,
    CalendarModule,
    FormsModule,
    CourtsAvailabilityPastSlotOverlayComponent,
    NgIf,
    DropdownModule,
    CourtsAvailabilityLegendComponent,
    CourtsAvailabilityOwnBookedSlotComponent,
    PaymentProcessingStatusDialogComponent,
    CourtsAvailabilityLoadingSlotComponent,
    MessagesModule,
  ],
  providers: [CourtsAvailabilityStore, CourtsAvailabilitySelectedSlotStore]
})
export class CourtsAvailabilityComponent implements OnInit, OnDestroy {

  @Input({ required: true }) config!: CourtsAvailabilityConfig;
  @Input() initialSportId: number | undefined;
  @Input({ required: true }) clubStore!: ClubStore;

  @Input('embedded') isEmbedded = false;

  availabilityStore = inject(CourtsAvailabilityStore);
  selectedSlotStore = inject(CourtsAvailabilitySelectedSlotStore);

  router = inject(Router);

  @ViewChild('selectedSlotEl', { read: ElementRef }) selectedSlotElement!: ElementRef;
  @ViewChild('selectedSlotEl') selectedSlotComp!: CourtsAvailabilitySelectedSlotComponent;
  @ViewChildren('bookableSlotEl', { read: ElementRef }) bookableSlotElements!: QueryList<ElementRef>;
  @ViewChild('.selected-slot-tooltip-content', { read: ElementRef }) selectedSlotTooltipContentElement!: ElementRef;

  private clickListener: (() => void) | undefined;

  constructor(private renderer: Renderer2, private el: ElementRef, @Inject(PLATFORM_ID) private platformId: string) {

  }

  sportSelectOptions = computed(() => {
    return this.availabilityStore.sportIds().map(sportId => {
      return { label: getSportNameById(sportId), value: sportId };
    });
  });

  setSelectedSport(sportId: number) {
    this.availabilityStore.setSelectedSport(sportId);
  }

  setSelectedDate(date: Date) {
    this.availabilityStore.setSelectedDate(convertDateValuesToTimezone(date, this.availabilityStore.timezone()));
  }

  selectSlot(slot: CourtsAvailabilityBookableSlot) {
    this.selectedSlotStore.selectSlot(slot, this.availabilityStore.timezone());
  }

  selectedDateIsToday: Signal<boolean> = computed(() => {
    const todayInClubTimezone = DateTime.now().setZone(this.availabilityStore.timezone()).startOf('day');
    console.log('todayInClubTimezone', todayInClubTimezone.toJSDate());
    console.log('selectedDate', this.availabilityStore.selectedDate().toJSDate());

    return todayInClubTimezone.toJSDate().toDateString() === this.availabilityStore.selectedDate().toJSDate().toDateString();
  });

  selectedDateInLocalTimeZone = computed(() => {
    return convertDateTimeValuesFromTimezone(this.availabilityStore.selectedDate(), this.availabilityStore.timezone());
  });

  ngOnInit() {
    this.availabilityStore.initStore(this.config);
    if (isPlatformBrowser(this.platformId)) {
      this.availabilityStore.loadCourtAvailability();
    }
    this.clickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      // this.onDocumentClick(event);
    });
  }

  activatedRoute = inject(ActivatedRoute);

  onDocumentClick(event: MouseEvent) {
    const nativeElements = this.bookableSlotElements.map(el => el.nativeElement);

    if (this.selectedSlotElement)
      nativeElements.push(this.selectedSlotElement.nativeElement);

    const selectedSlotTooltipContentElement = document.getElementById('selected-slot-tooltip-content');

    console.log('selectedSlotComp', this.selectedSlotComp.tooltipComponentRef?.rootNodes);

    console.log('selectedSlotTooltipContentElement', selectedSlotTooltipContentElement);
    if (this.selectedSlotComp.tooltipComponentRef?.rootNodes)
      nativeElements.push(this.selectedSlotComp.tooltipComponentRef?.rootNodes[0])

    console.log('click target', event.target);
    console.log('nativeElements', nativeElements);

    const clickedInside = nativeElements.some(el => el.contains(event.target));

    if (!clickedInside) {
      // this.selectedSlotStore.unselectSlot();
    }
  }

  minDate = computed(() => {
    const currentDate = DateTime.now().setZone(this.availabilityStore.timezone()).startOf('day');
    return convertDateValuesFromTimezone(currentDate.toJSDate(), this.availabilityStore.timezone());
  });

  errorMessages = computed(() => {
    return this.availabilityStore.error() ? [{ severity: 'error', detail: this.availabilityStore.error()!, closable: false }] : [];
  });

  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
    }
  }

  redirectToMyBookingsPage() {
    console.log('Payment Success - Redirecting to my bookings page');
    // wait 200ms before redirecting to my bookings page
    setTimeout(() => {
      this.router.navigate(['/my-bookings']).then(() => {
          console.log('Redirected to my bookings page');
        }
      )
    }, 500);
  }
}
