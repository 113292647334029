import { CourtsAvailabilitySlot } from "./courts-availability-slot";
import { BookableSlot, Court, CourtReservation } from "../../../shared/graphql/generated/graphql";
import { CourtsAvailabilityBookableSlot } from "./courts-availability-bookable-slot";

export class CourtsAvailabilityBookedSlot extends CourtsAvailabilitySlot {
  id: string;

  constructor({ startTime, endTime, court, id }: { startTime: Date, endTime: Date, court: Court, id: string }) {
    super({ startTime, endTime, court });
    this.id = id;
  }
}

export function courtsAvailabilityOwnBookedSlotsFromGqlCourtReservations(reservations: CourtReservation[], courts: Court[]): CourtsAvailabilityBookedSlot[] {
  return reservations.map(reservation => {
    const court = courts.find(court => court.id === reservation.courtId);
    if (!court) {
      console.error(`Court with id ${reservation.courtId} not found for reservation ${reservation.id}`);
      console.error('Available courts', courts);
    }
    const startTime = new Date(reservation.startTime);
    const endTime = new Date(startTime.getTime() + reservation.duration * 60 * 1000);
    console.log('startTime', startTime);
    console.log('endTime', endTime);
    return new CourtsAvailabilityBookedSlot({
      id: reservation.id,
      startTime: startTime,
      endTime: endTime,
      court: court!,
    });
  }).filter(slot => slot.court);
}
