import { ActivatedRouteSnapshot, Resolve, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from "@angular/core";
import { Club, Event } from "../../../shared/graphql/generated/graphql";
import { EventsService } from "../../events/services/events.service";
import { catchError, EMPTY, map, Observable } from "rxjs";
import { ClubsService } from "../services/clubs.service";
import { validate as uuidValidate } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ClubResolver implements Resolve<Club> {

  constructor(public clubsService: ClubsService, public router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Club> {
    let clubIdOrSlug = route.paramMap.get('id');

    if (!clubIdOrSlug) {
      //check if there is clubId in the query params
      clubIdOrSlug = route.queryParamMap.get('clubId');
      console.log('route queryParamMap', route.queryParamMap);
    }

    if (clubIdOrSlug && uuidValidate(clubIdOrSlug)) {
      console.log("Club by ID");
      return this.clubsService.getClubById(clubIdOrSlug)
        .pipe(map(club => {
          if (!club) {
            throw new Error("Club not found");
          }
          return club as Club
        }))
        .pipe(catchError(() => {
          this.router.navigate(["/404"]);
          return EMPTY;
        }));
    } else if (clubIdOrSlug) {
      console.log("Club by Slug");
      return this.clubsService.getClubBySlug(clubIdOrSlug)
        .pipe(map(club => {
          if (!club) {
            throw new Error("Club not found");
          }
          return club as Club
        }))
        .pipe(catchError(() => {
          this.router.navigate(["/404"]);
          return EMPTY;
        }));
    }

    return EMPTY;
  }
}

