<div>
  <div class="mb-4">
    <div class="flex flex-row">
      <div class="mr-3">
        <p-dropdown
          [options]="sportSelectOptions()"
          [ngModel]="availabilityStore.selectedSportId()"
          (ngModelChange)="setSelectedSport($event)"
          i18n-placeholder
          placeholder="Select a Sport" />
      </div>

      <div>
        <p-calendar [showIcon]="true" [ngModel]="selectedDateInLocalTimeZone()" (ngModelChange)="setSelectedDate($event)" [minDate]="minDate()"/>
      </div>
    </div>

  </div>
    <cs-courts-availability-grid [hours]="availabilityStore.hours()" [courts]="availabilityStore.courtsForSport()">
      @if (availabilityStore.loading() || !availabilityStore.error()) {
      <div *ngFor="let court of availabilityStore.courtsForSport()" style="height: 40px; line-height: 40px;" class="relative">
        @if (!availabilityStore.loading()) {
          <cs-courts-availability-bookable-slot #bookableSlotEl *ngFor="let bookableSlot of availabilityStore.getBookableSlotsByCourt()(court.id)" [timezone]="availabilityStore.timezone()"
                                                [slot]="bookableSlot" [startTime]="availabilityStore.startTime()" (click)="selectSlot(bookableSlot)">
          </cs-courts-availability-bookable-slot>

          <cs-courts-availability-blocked-slot *ngFor="let blockedSlot of availabilityStore.getBlockedSlotsByCourt()(court.id)"
                                               [slot]="blockedSlot" [startTime]="availabilityStore.startTime()" [timezone]="availabilityStore.timezone()">
          </cs-courts-availability-blocked-slot>

          <cs-courts-availability-own-booked-slot *ngFor="let bookedSlot of availabilityStore.getOwnBookedSlotsByCourt()(court.id)"
                                                  [slot]="bookedSlot" [startTime]="availabilityStore.startTime()" [timezone]="availabilityStore.timezone()">
          </cs-courts-availability-own-booked-slot>
        } @else if (availabilityStore.loading()) {
          <cs-courts-availability-loading-slot [startTime]="availabilityStore.startTime()" [endTime]="availabilityStore.endTime()" [timezone]="availabilityStore.timezone()"></cs-courts-availability-loading-slot>
        }
      </div>
      <cs-courts-availability-selected-slot #selectedSlotEl [startTime]="availabilityStore.startTime()" [clubStore]="clubStore" [timezone]="availabilityStore.timezone()" [isEmbedded]="isEmbedded"></cs-courts-availability-selected-slot>
      <cs-courts-availability-past-slot-overlay *ngIf="selectedDateIsToday()" [startTime]="availabilityStore.startTime()" [endTime]="availabilityStore.endTime()" [timezone]="availabilityStore.timezone()"></cs-courts-availability-past-slot-overlay>
    } @else if (availabilityStore.error()) {
      <div class="flex justify-content-center align-items-center h-full">
        <div class="">
          <p-messages [escape]="false" [value]="errorMessages()" [enableService]="false" />
        </div>
      </div>
    }
    </cs-courts-availability-grid>
</div>

<cs-payment-processing-status-dialog (onPaymentSuccess)="redirectToMyBookingsPage()"></cs-payment-processing-status-dialog>
