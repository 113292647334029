import { Pipe, PipeTransform } from '@angular/core';
import { Day, OpeningHour } from "../../../shared/graphql/generated/graphql";

@Pipe({
  name: 'clubOpeningHoursDaysTr',
  standalone: true
})
export class ClubOpeningHoursDaysTrPipe implements PipeTransform {

  transform(openingHourDay: Day, ...args: unknown[]): unknown {
    switch (openingHourDay) {
      case Day.Monday:
        return $localize `:@@days.monday:Monday`;
      case Day.Tuesday:
        return $localize `:@@days.tuesday:Tuesday`;
      case Day.Wednesday:
        return $localize `:@@days.wednesday:Wednesday`;
      case Day.Thursday:
        return $localize `:@@days.thursday:Thursday`;
      case Day.Friday:
        return $localize `:@@days.friday:Friday`;
      case Day.Saturday:
        return $localize `:@@days.saturday:Saturday`;
      case Day.Sunday:
        return $localize `:@@days.sunday:Sunday`;
      case Day.MondayFriday:
        return $localize `:@@days.mondayFriday:Monday - Friday`;
      case Day.SaturdaySunday:
        return $localize `:@@days.saturdaySunday:Saturday - Sunday`;
      case Day.MondaySunday:
        return $localize `:@@days.mondaySunday:Monday - Sunday`;
        case Day.Holidays:
        return $localize `:@@days.holidays:Holidays`;
      default:
        return openingHourDay;
    }
  }

}
