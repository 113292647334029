<cs-checkout-dialog [overviewTemplate]="overviewTemplate"
                    [disclaimerTemplate]="disclaimerTemplate"
                    [alternativePaymentOptionTemplate]="cashPaymentPossible() ? alternativePaymentOptionTemplate : undefined"
                    [checkoutParams$]="checkoutParams$"
                    [initializePaymentFn]="initializeReservationPayment.bind(this)"
                    [alternativePaymentMethodFn]="reservationByCashPayment.bind(this)"
>
</cs-checkout-dialog>

<ng-template #overviewTemplate>
    <div class="text-xl text-900 font-medium mb-4">{{ clubStore.club()!.name }}</div>
    <div>
      <div class="flex justify-content-between my-3 px-1">
        <div class="text-800 font-medium" i18n>Start</div>
        <div class="text-500">{{ selectedSlotStore.selectedDuration()!.startTime | dateInTimezone:'medium':selectedSlotStore.timezone()! }}</div>
      </div>
      <div class="border-top-1 surface-border w-full"></div>
      <div class="flex justify-content-between my-3 px-1">
        <div class="text-800 font-medium" i18n>Duration</div>
        <div class="text-500">{{ selectedSlotStore.selectedDuration()!.duration }} min</div>
      </div> <div class="border-top-1 surface-border w-full"></div>
      <div class="flex justify-content-between my-3 px-1">
        <div>
          <div class="text-800 font-medium" i18n>Price</div>
          <div class="text-500 text-sm" i18n>incl. VAT.</div>
        </div>
        <div class="text-500 text-2xl text-primary">{{ selectedSlotStore.selectedDuration()!.price | currency:'EUR':'symbol' }}</div>
      </div>
    </div>
</ng-template>

<ng-template #disclaimerTemplate>
  <ng-container>
    <div class="pt-4 pb-2">
      <div class="text-500 text-xs">
        <p *ngIf="refundableUntil()" i18n>If you cancel the reservation until {{ refundableUntil() | dateInTimezone:'short':clubStore.club()!.ianaTimezone! }} your reservation fee will be refunded.</p>
        <p i18n>Depending on your method of payment we can only refund you the reservation price minus the transaction costs when cancelling.</p>
        <p i18n>By continuing you agree to our <a href="/terms-of-service" class="text-primary">terms of sale</a> and <a href="/privacy-policy" class="text-primary">privacy policy</a>.</p>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #alternativePaymentOptionTemplate let-onClick="onClick" let-loading="loading" let-disabled="disabled">
  <p-button severity="secondary" styleClass="w-full justify-content-center" (click)="onClick()" [loading]="loading()" [disabled]="disabled()">
    <div class="flex flex-row align-items-center">
      <div class="flex flex-column line-height-1">
        <div class="flex flex-row align-self-center align-items-center">
          <i class="pi pi-money-bill mr-2"></i>
          <span i18n class="font-medium">Pay cash on site</span>
        </div>
        <!--<div>
          <span class="text-xs" i18n>Only for club members</span>
        </div>-->
      </div>

      <div class="text-xl font-medium ml-4">
        {{ selectedSlotStore.selectedDuration()!.cashPrice | currency:'EUR':'symbol' }}
      </div>
    </div>
  </p-button>
</ng-template>
