import { ComponentStore } from "@ngrx/component-store";
import { tapResponse } from '@ngrx/operators';
import { Inject, Injectable, LOCALE_ID, NgZone } from "@angular/core";
import {
  Event,
  EventType,
  LeaderboardEntry, ListEventInput,
  Player,
  PlayerToEvent
} from "../../../../shared/graphql/generated/graphql";
import { catchError, combineLatestWith, EMPTY, exhaustMap, map, Observable, switchMap, tap } from "rxjs";
import { getSportNameById } from "../../../../shared/utils/sport.utils";
import { EventStatus } from "../../enums/event-status.enum";
import { BranchIoService } from "../../../../shared/services/branch-io.service";
import { formatCurrency } from "@angular/common";
import { EventsService } from "../../services/events.service";
import { Store } from "@ngrx/store";
import { selectPlayerSelf } from "../../../player-self/store/player-self.selectors";
import { showErrorToast } from "../../../../shared/store/toast/toast.actions";

export interface EventListState {
  events: Event[];
  isLoading: boolean;
  showError: boolean;
}
@Injectable()
export class EventListEmbeddedStore extends ComponentStore<EventListState> {

  constructor(readonly branchIoService: BranchIoService, @Inject(LOCALE_ID) private readonly locale: string, readonly eventService: EventsService, readonly store: Store) {
    super({ events: [], isLoading: true, showError: false});
  }

  readonly events$: Observable<Event[]> = this.select(state => state.events);

  readonly eventsPreview$ = (range: number): Observable<Event[]> =>
    this.events$.pipe(map((events) => events.slice(0, range)));

  // Parameterized selector to determine if "Show More" should be displayed
  readonly eventsPreviewDisplayShowMore$ = (range: number): Observable<boolean> =>
    this.events$.pipe(
      map(
        (events) =>
          events.length > range && !this.get((state) => state.isLoading) && !this.get((state) => state.showError)
      )
    );
  readonly eventsPreviewSingle$: Observable<Event> = this.select(state => state.events[0]);
  readonly eventsPreviewSingleDisplayShowMore$: Observable<boolean> = this.select(state => state.events.length > 1 && !state.isLoading && !state.showError);

  readonly isLoading$: Observable<boolean> = this.select(state => state.isLoading);

  readonly showError$: Observable<boolean> = this.select(state => state.showError);

  readonly updateEvents = this.updater((state, events: Event[]) => {
    return {...state, events}
  });

  readonly loadEvents = this.effect((filters$: Observable<ListEventInput>) => {
    return filters$.pipe(
      tap(filters$ => {
        this.patchState({ isLoading: true })
      }),
      switchMap(filters$ => {
        return this.eventService.getEventsByFilter(filters$).pipe(
          tapResponse(
            (events) => {
              this.patchState({ isLoading: false, events, showError: false })
            },
            (error) => {
              this.patchState({ isLoading: false, showError: true })
            }
          )
        );
      })
    );
  });
}
