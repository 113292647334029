import { Component } from '@angular/core';
import { CourtsAvailabilitySlotComponent } from "../courts-availability-slot/courts-availability-slot.component";

@Component({
  selector: 'cs-courts-availability-blocked-slot',
  standalone: true,
  imports: [],
  templateUrl: './courts-availability-blocked-slot.component.html',
  styleUrl: './courts-availability-blocked-slot.component.scss'
})
export class CourtsAvailabilityBlockedSlotComponent extends CourtsAvailabilitySlotComponent {

}
