import { inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";
import { DateTime } from "luxon";
import { convertDateValuesFromTimezone } from "../utils/localization.utils";

@Pipe({
  name: 'dateInTimezone',
  standalone: true
})
export class DateInTimezonePipe implements PipeTransform {

  private locale = inject(LOCALE_ID);

  private datePipe: DatePipe;

  constructor() {
    this.datePipe = new DatePipe(this.locale);
  }

  transform(value: Date, format: string = 'mediumDate', timezone: string): string | null {
   /* const localTimezone = DateTime.local().zoneName;
    const localDate = DateTime.fromJSDate(value).setZone(localTimezone);
    console.log('localDate', localDate.toISO());
    const dateTime = localDate.setZone(timezone, { keepLocalTime: false });
    console.log('dateTime tz', dateTime.toISO(), timezone);
    // const datTimeInTimezone = dateTime.setZone(timezone, { keepLocalTime: false });*/
    const dateTimeInLocalZone = new Date(value);
    const dateTimeInAccountZone = convertDateValuesFromTimezone(dateTimeInLocalZone, timezone);
    return this.datePipe.transform(dateTimeInAccountZone, format, timezone, this.locale);
  }

}
