import { Component, Input } from '@angular/core';
import { CourtsAvailabilitySlot } from "../../../../../entities/courts-availability-slot";
import { DateTime } from "luxon";

@Component({
  selector: '[cs-courts-availability-bookable-slot]',
  standalone: true,
  imports: [],
  templateUrl: './courts-availability-slot.component.html',
  styleUrl: './courts-availability-slot.component.scss'
})
export class CourtsAvailabilitySlotComponent {

  @Input() slot!: CourtsAvailabilitySlot;

  @Input({ required: true }) startTime!: DateTime;

  @Input({ required: true }) timezone!: string;


  calculateSlotLeftOffsetFromStartTime(startTime?: Date): string {
    if (!startTime) {
      return '0px';
    }
    const minutesFromStart = (startTime.getTime() - this.startTime.toJSDate().getTime()) / 60000;
    const offset = this.getPixelsFromMinutes(minutesFromStart);
    return offset;
  }

  calculateWidthFromDuration(startTime?: Date, endTime?: Date): string {
    if (!startTime || !endTime) {
      return '0px';
    }
    const durationInMinutes = (endTime.getTime() - startTime.getTime()) / 60000;
    const width = this.getPixelsFromMinutes(durationInMinutes);
    return width;
  }

  getPixelsFromMinutes(minutes: number): string {
    const pixelPerMinute = 40 / 60;
    const width = minutes * pixelPerMinute;
    return width.toString() + 'px';
  }

}
