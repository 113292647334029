import { Pipe, PipeTransform } from '@angular/core';
import { OpeningHour } from "../../../shared/graphql/generated/graphql";

@Pipe({
  name: 'clubOpeningHourTimes',
  standalone: true
})
export class ClubOpeningHourTimesPipe implements PipeTransform {

  transform(openingHour: OpeningHour, ...args: unknown[]): string {
    return `${this.formatTime(openingHour.openTime)} - ${this.formatTime(openingHour.closeTime)}`;
  }

  formatTime(time: string): string {
    const [hours, minutes, _] = time.split(':');
    const date = new Date();
    date.setHours(+hours);
    date.setMinutes(+minutes);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

}
